import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Lock, ArrowRight } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import { confirmSignUp } from 'aws-amplify/auth';

export default function ConfirmOtp() {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    onetimepassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!location.state || !location.state.email || !location.state.userId) {
      toast.error("An error occurred. Please try signing up again.", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
      setIsLoading(false);
      return;
    }

    const { onetimepassword } = formData;
    const username = location.state.email;

    await handleSignUpConfirmation({ username, confirmationCode: onetimepassword });
    setIsLoading(false);
  };

  async function handleSignUpConfirmation({ username, confirmationCode }) {
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode
      });

      if (isSignUpComplete) {
        toast.success("Sign up confirmed successfully!", {
          style: { fontWeight: "bold", fontSize: "16px" }
        });
        navigate("/admin_aies_app/Additional_Information", { 
          state: { email: location.state.email, userId: location.state.userId } 
        });
      } else {
        toast.info("Additional steps required. Please follow the instructions.", {
          style: { fontWeight: "bold", fontSize: "16px" }
        });
        console.log('Next step:', nextStep);
      }
    } catch (error) {
      console.log('error confirming sign up', error);
      toast.error("Failed to confirm sign up. Please try again.", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-indigo-50 to-purple-100 flex flex-col">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-bl from-blue-300 to-indigo-400 rounded-bl-full opacity-20 transform rotate-12"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-to-tr from-indigo-300 to-purple-400 rounded-tr-full opacity-20 transform -rotate-12"></div>
      
      <div className="flex justify-between p-6 relative z-10">
        <div className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
          AriesConnect
        </div>
        <a href="/admin_aries_app/faq" className="text-gray-600 hover:text-blue-600 transition-colors">
          Need Help?
        </a>
      </div>
      
      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-md relative z-10">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <div className="bg-white backdrop-blur-lg bg-opacity-70 rounded-3xl shadow-2xl overflow-hidden border border-gray-100">
              <div className="px-8 pt-8 pb-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">
                  Confirm OTP
                </h1>
                <p className="text-gray-500 mb-8">
                  Please enter the OTP sent to your email
                </p>
                
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">One-Time Password (OTP)</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Lock className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="number"
                        name="onetimepassword"
                        value={formData.onetimepassword}
                        onChange={handleChange}
                        placeholder="Enter OTP"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 rounded-xl font-semibold hover:from-blue-600 hover:to-indigo-700 transition-all transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed flex justify-center items-center group"
                  >
                    {isLoading ? (
                      <div className="w-6 h-6 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                    ) : (
                      <>
                        Verify OTP
                        <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      <ToastContainer 
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}