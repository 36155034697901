import React, { useState, useEffect } from "react";
import {
  List, ListItem, ListItemAvatar, ListItemText, Avatar,
  Typography, Box, Skeleton, IconButton, Fade,
  Container, TextField, AppBar, Toolbar, Button, useMediaQuery,
  Drawer, ListItemIcon, Grow
} from "@mui/material";
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import { DataStore } from 'aws-amplify/datastore';
import { USER } from '../models';
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import HelpIcon from '@mui/icons-material/Help';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

export default function Contact() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const result = await DataStore.query(USER);
      const modifiedContacts = result.map((item) => ({
        name: item.name,
        phone: item.phone,
        email: item.email,
      }));
      setContacts(modifiedContacts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleNavigation = (route) => {
    navigate(route);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.error('Error signing out:', e);
    }
  };

  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.open(mailtoLink);
  };

  const handleCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const drawerContent = (
    <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" component="div">
          Aries Connect
        </Typography>
      </Box>
      <List sx={{ flexGrow: 1 }}>
        {[
          { text: 'Home', icon: <HomeIcon />, action: () => handleNavigation('/admin_aries_app/Home') },
          { text: 'User Info', icon: <PersonIcon />, action: () => handleNavigation('/admin_aries_app/UserInfo') },
          { text: 'Messages', icon: <MessageIcon />, action: () => handleNavigation('/admin_aries_app/Msg') },
          { text: 'Help', icon: <HelpIcon />, action: handleHelp },
        ].map((item, index) => (
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + index * 200 }} key={item.text}>
            <StyledListItem button onClick={item.action}>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          </Grow>
        ))}
      </List>
      <Fade in={true} style={{ transitionDelay: '800ms' }}>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
            sx={{ backgroundColor: '#ff5309', '&:hover': { backgroundColor: '#e64a00' } }}
          >
            Logout
          </Button>
        </Box>
      </Fade>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <ContactsIcon sx={{ fontSize: 28, mr: 2 }} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Contacts
            </Typography>
            {!isMobile && (
              <>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Home')}>Home</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/UserInfo')}>User Info</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Msg')}>Messages</Button>
                <Button color="inherit" onClick={handleHelp}>Help</Button>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        <StyledDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {drawerContent}
        </StyledDrawer>

        <Container maxWidth="md" sx={{ mt: 4 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search contacts"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
            }}
            sx={{ mb: 3 }}
          />

          <Box sx={{ mt: 2 }}>
            {loading ? (
              Array.from({ length: 5 }, (_, index) => (
                <Skeleton key={index} variant="rectangular" width="100%" height={60} sx={{ mb: 2, borderRadius: 1 }} />
              ))
            ) : (
              <List>
                {filteredContacts
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((contact, index) => (
                    <Fade in={true} timeout={300 + index * 50} key={index}>
                      <ListItem
                        sx={{
                          bgcolor: 'background.paper',
                          mb: 1,
                          borderRadius: 1,
                          '&:hover': {
                            bgcolor: 'action.hover',
                            transform: 'translateY(-4px)',
                            transition: 'transform 0.3s ease-in-out',
                          },
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: 'primary.main' }}>
                            {contact.name.charAt(0).toUpperCase()}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="body1" sx={{ fontWeight: 500, mb: 0.5 }}>
                              {contact.name}
                            </Typography>
                          }
                          secondary={
                            <Typography variant="body2" color="text.secondary">
                              {contact.phone}
                            </Typography>
                          }
                        />
                        <IconButton onClick={() => handleCall(contact.phone)} color="primary">
                          <CallIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEmail(contact.email)} color="primary">
                          <MailIcon />
                        </IconButton>
                      </ListItem>
                    </Fade>
                  ))
                }
              </List>
            )}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}