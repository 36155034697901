import React, { useEffect, useState } from 'react';
import { getCurrentUser } from "aws-amplify/auth";
import { DataStore } from 'aws-amplify/datastore';
import { useNavigate } from "react-router-dom";
import { Notify } from '../models';
import { signOut } from "aws-amplify/auth";
import {
  AppBar, Toolbar, Typography, Button, Box, Container, List, ListItem,
  ListItemText, ListItemIcon, IconButton, TextField, Drawer,
  useMediaQuery, Fade, Grow, CircularProgress
} from '@mui/material';
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import {
  Notifications as NotificationsIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Message as MessageIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon
} from '@mui/icons-material';

const theme = createTheme({
  // ... (keep the existing theme configuration)
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

function Msg() {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const currentUser = await getCurrentUser();
      const notifications = await DataStore.query(Notify);
      const userNotifications = notifications.filter(notification => notification.username === currentUser.username);
      userNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setNotifications(userNotifications);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setLoading(false);
    }
  };

  const markAsRead = async (notification) => {
    try {
      const aman = notification.id;
      navigate("/admin_aries_app/Msgdetails", { state: { aman } });

      await DataStore.save(Notify.copyOf(notification, item => {
        item.read = true;
        item.seen = true;
      }));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const formatTimestamp = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/admin_aries_app/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleHelp = () => {
    const email = 'syserp@aries.res.in';
    const subject = 'Need help With Aries Connect';
    window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}`);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredNotifications = notifications.filter(notification =>
    notification.message.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const drawerContent = (
    <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" component="div">
          Aries Connect
        </Typography>
      </Box>
      <List sx={{ flexGrow: 1 }}>
        {[
          { text: 'Home', icon: <HomeIcon />, action: () => handleNavigation('/admin_aries_app/Home') },
          { text: 'User Info', icon: <PersonIcon />, action: () => handleNavigation('/admin_aries_app/UserInfo') },
          { text: 'Messages', icon: <MessageIcon />, action: () => handleNavigation('/admin_aries_app/Msg') },
          { text: 'Help', icon: <HelpIcon />, action: handleHelp },
        ].map((item, index) => (
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + index * 200 }} key={item.text}>
            <StyledListItem button onClick={item.action}>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          </Grow>
        ))}
      </List>
      <Fade in={true} style={{ transitionDelay: '800ms' }}>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
            sx={{ backgroundColor: '#ff5309', '&:hover': { backgroundColor: '#e64a00' } }}
          >
            Logout
          </Button>
        </Box>
      </Fade>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Messages
            </Typography>
            {!isMobile && (
              <>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Home')}>Home</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/UserInfo')}>User Info</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Msg')}>Messages</Button>
                <Button color="inherit" onClick={handleHelp}>Help</Button>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        <StyledDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {drawerContent}
        </StyledDrawer>

        <Container maxWidth="md" sx={{ mt: 4 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search messages..."
            InputProps={{
              startAdornment: <SearchIcon color="action" />,
            }}
            onChange={handleSearch}
            sx={{ mb: 3 }}
          />

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : filteredNotifications.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <Typography variant="h6">No notifications found</Typography>
            </Box>
          ) : (
            <List>
              {filteredNotifications.map((notification, index) => (
                <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 500 + index * 100 }} key={notification.id}>
                  <ListItem
                    button
                    onClick={() => markAsRead(notification)}
                    sx={{
                      mb: 2,
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      boxShadow: 1,
                      '&:hover': { 
                        bgcolor: 'action.hover',
                        transform: 'translateY(-4px)',
                        transition: 'transform 0.3s ease-in-out',
                      },
                    }}
                  >
                    <ListItemIcon>
                      <NotificationsIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={notification.message}
                      secondary={`From Admin - ${formatTimestamp(notification.createdAt)}`}
                    />
                    {!notification.read && (
                      <Box
                        sx={{
                          bgcolor: 'secondary.main',
                          color: 'secondary.contrastText',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          fontSize: '0.75rem',
                          fontWeight: 'bold',
                        }}
                      >
                        New
                      </Box>
                    )}
                  </ListItem>
                </Grow>
              ))}
            </List>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Msg;