import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const ImageViewer = () => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = localStorage.getItem('IMG_URL');
    setLink(url);
    setLoading(false); // Set loading to false once the image is loaded
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
      <img
        src={link}
        alt="Loading"
        style={{
          height: "100%",
          width: "100%",
          display: loading ? "none" : "block",
        }}
      />
    </div>
  );
};

export default ImageViewer;
