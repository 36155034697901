// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { AdminProgressLog, Notify, AuditLog, Permissons, USER } = initSchema(schema);

export {
  AdminProgressLog,
  Notify,
  AuditLog,
  Permissons,
  USER
};