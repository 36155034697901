import { keyframes, styled } from '@mui/material/styles';
import { AppBar, Avatar, IconButton, Button, Drawer } from '@mui/material';

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(255, 255, 255, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); }
`;

export const PremiumAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(135deg, #4e8bed 0%, #2563eb 50%, #1e3a8a 100%)`,
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent)',
    animation: `${shimmer} 2s infinite`,
  },
  '& .MuiToolbar-root': {
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: '#FFFFFF',
  background: 'rgba(255, 255, 255, 0)',
  borderRadius: '50%',
  padding: 8,
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
    transform: 'scale(1.1)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `2px solid ${theme.palette.common.white}`,
  boxShadow: '0 0 0 2px rgba(59, 130, 246, 0.5)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 0 0 4px rgba(59, 130, 246, 0.7)',
  },
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& img': {
    height: 40,
    marginRight: theme.spacing(1),
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
  },
}));

export const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  margin: theme.spacing(0, 1),
  borderRadius: 20,
  padding: theme.spacing(1, 2),
  transition: 'all 0.3s ease',
  background: 'rgba(255, 255, 255, 0.1)',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
    transform: 'translateY(-2px)',
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    background: '#1e3a8a', // Single color for the entire drawer
    color: theme.palette.common.white,
    borderRight: 'none',
  },
}));

export const UserInfoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4, 2),
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
    opacity: 0.5,
  },
}));

export const DrawerAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  marginBottom: theme.spacing(2),
  border: `3px solid ${theme.palette.common.white}`,
  boxShadow: `0 0 0 4px #3b82f6, 0 0 0 8px rgba(255, 255, 255, 0.3)`,
  animation: `${pulse} 2s infinite`,
}));

export const UserName = styled('h6')(({ theme }) => ({
  margin: 0,
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: theme.palette.common.white,
  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
  marginBottom: theme.spacing(1),
}));

export const UserRole = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: '0.9rem',
  color: theme.palette.common.white,
  opacity: 0.8,
  textTransform: 'uppercase',
  letterSpacing: '1px',
}));