import React, { useEffect, useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Notify } from '../models';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from "aws-amplify/auth";
import {
  Typography, Container, Box, CircularProgress, AppBar, Toolbar,
  Button, IconButton, Avatar, Paper, Divider
} from '@mui/material';
import { ThemeProvider, createTheme, alpha } from '@mui/material/styles';
import {
  ArrowBack as ArrowBackIcon,
  AccessTime as AccessTimeIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import notificationIcon from '../Images/man.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.1rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        },
      },
    },
  },
});

function MsgDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { aman } = location.state || {};
  const [msg, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchMessage() {
      try {
        const models = await DataStore.query(Notify);
        const foundMsg = models.find(model => model.id === aman);
        if (foundMsg) {
          setMessage(foundMsg);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching message:', error);
        setLoading(false);
      }
    }

    fetchMessage();
  }, [aman]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'MMMM dd, yyyy HH:mm');
  };

  const handleBack = () => {
    navigate("/admin_aries_app/Msg");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0} color="transparent">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleBack}
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Message Details
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Paper elevation={3} sx={{ 
              p: 4, 
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
              }
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Avatar
                  src={notificationIcon}
                  alt="Admin"
                  sx={{ width: 60, height: 60, mr: 2 }}
                />
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    Admin Message
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                    <AccessTimeIcon sx={{ fontSize: 18, mr: 0.5, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(msg?.createdAt)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Divider sx={{ my: 3 }} />

              <Typography variant="body1" sx={{ 
                mb: 3, 
                lineHeight: 1.6,
                color: 'text.primary',
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
                p: 2,
                borderRadius: 1,
                borderLeft: `4px solid ${theme.palette.primary.main}`
              }}>
                {msg?.message}
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3 }}>
                <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
                <Typography variant="subtitle2" color="text.secondary">
                  Admin ARIES
                </Typography>
              </Box>
            </Paper>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default MsgDetails;