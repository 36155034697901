// AnimatedStyles.js
import { keyframes, styled } from '@mui/material/styles';
import { Box, IconButton, Card } from '@mui/material';

// New animations
const popIn = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ripple = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

// Enhanced MenuItemCard with touch feedback
export const EnhancedMenuItemCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: 16,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  animation: `${popIn} 0.5s ease-out`,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 5,
    height: 5,
    backgroundColor: 'rgba(59, 130, 246, 0.3)',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
  },

  '&:active': {
    transform: 'scale(0.95)',
    '&::before': {
      animation: `${ripple} 0.6s ease-out`,
    },
  },

  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(59, 130, 246, 0.15)',
    background: 'linear-gradient(145deg, #ffffff, #e8f0fe)',
    '& .icon-wrapper': {
      animation: `${float} 2s ease-in-out infinite`,
    },
  },

  [theme.breakpoints.down('sm')]: {
    height: '130px',
    padding: theme.spacing(1.5),
  }
}));

// Enhanced IconWrapper with animations
export const EnhancedIconWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '80px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1.5),
  transition: 'all 0.3s ease',
  animation: `${slideUp} 0.5s ease-out`,

  '&.icon-wrapper': {
    transition: 'transform 0.3s ease',
  },

  '&:hover': {
    transform: 'scale(1.1)',
  }
}));

// Enhanced mobile navigation button
export const MobileNavButton = styled(IconButton)(({ theme }) => ({
  color: '#FFFFFF',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%) scale(0)',
    transition: 'transform 0.3s ease',
  },

  '&:active::before': {
    transform: 'translate(-50%, -50%) scale(1.5)',
    opacity: 0,
  },

  '& .MuiSvgIcon-root': {
    transition: 'transform 0.3s ease',
  },

  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.2)',
  }
}));

// Enhanced Drawer animation
export const EnhancedDrawer = styled(Box)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    animation: `${slideUp} 0.3s ease-out`,
    background: 'linear-gradient(145deg, #1e40af, #1e3a8a)',
    
    '& .MuiListItem-root': {
      transition: 'all 0.3s ease',
      transform: 'translateX(-20px)',
      opacity: 0,
      animation: `${slideUp} 0.5s ease-out forwards`,

      '&:nth-of-type(1)': { animationDelay: '0.1s' },
      '&:nth-of-type(2)': { animationDelay: '0.2s' },
      '&:nth-of-type(3)': { animationDelay: '0.3s' },
      '&:nth-of-type(4)': { animationDelay: '0.4s' },
    }
  }
}));

// Image slider with smooth transitions
export const EnhancedImageSlider = styled(Box)(({ theme }) => ({
  height: 200,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  position: 'relative',
  boxShadow: '0 8px 16px rgba(0,0,0,0.08)',
  
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 1s ease-in-out',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  [theme.breakpoints.up('sm')]: {
    height: 280,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '30%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.3), transparent)',
    pointerEvents: 'none',
  }
}));