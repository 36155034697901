import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Eye, EyeOff, User, Phone, Briefcase, Building, FileText, Hash, CreditCard, Radio } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import { DataStore } from 'aws-amplify/datastore';
import { USER } from '../models';

export default function AdditionalInformation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    username: "",
    phonenumber: "",
    designation: "",
    userdivision: "",
    employeeCode: "",
    extensionNumber: "",
    panNumber: "",
    serviceType: "active",
    role: "regular"
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!location.state || !location.state.email || !location.state.userId) {
      toast.error("Missing user information. Please try again.", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
      setIsLoading(false);
      return;
    }

    const useremail = location.state.email;
    const userid = location.state.userId;

    try {
      const userCopy = {
        query: USER,
        variables: {
          input: {
            email: useremail,
            name: formData.username,
            phone: "+91" + formData.phonenumber,
            designation: formData.designation,
            active: formData.serviceType === "active",
            division: formData.userdivision,
            admin: true,
            galleryAdmin: true,
            noticeAdmin: true,
            pan: formData.panNumber,
            uid: userid,
            userGroup: formData.role,
            employeeCode: formData.employeeCode,
            extensionNumber: formData.extensionNumber
          }
        }
      };
      
      await DataStore.save(userCopy);
      
      toast.success("Profile updated successfully", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
      navigate('/admin_aries_app/', { state: { userid } });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error("Failed to update profile. Please try again.", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
    }
    
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-indigo-50 to-purple-100 flex flex-col">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-bl from-blue-300 to-indigo-400 rounded-bl-full opacity-20 transform rotate-12"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-to-tr from-indigo-300 to-purple-400 rounded-tr-full opacity-20 transform -rotate-12"></div>
      
      <div className="flex justify-between p-6 relative z-10">
        <div className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
          AriesConnect
        </div>
        <a href="/admin_aries_app/faq" className="text-gray-600 hover:text-blue-600 transition-colors">
          Need Help?
        </a>
      </div>
      
      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-md relative z-10">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <div className="bg-white backdrop-blur-lg bg-opacity-70 rounded-3xl shadow-2xl overflow-hidden border border-gray-100">
              <div className="px-8 pt-8 pb-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">
                  Update Your Profile
                </h1>
                <p className="text-gray-500 mb-8">Please provide your additional information</p>
                
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <User className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Phone className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="tel"
                        name="phonenumber"
                        value={formData.phonenumber}
                        onChange={handleChange}
                        placeholder="Enter your phone number"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Designation</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Briefcase className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="text"
                        name="designation"
                        value={formData.designation}
                        onChange={handleChange}
                        placeholder="Enter your designation"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Division</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Building className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="text"
                        name="userdivision"
                        value={formData.userdivision}
                        onChange={handleChange}
                        placeholder="Enter your division"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Employee Code</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FileText className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="text"
                        name="employeeCode"
                        value={formData.employeeCode}
                        onChange={handleChange}
                        placeholder="Enter your employee code"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Extension Number</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Hash className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="text"
                        name="extensionNumber"
                        value={formData.extensionNumber}
                        onChange={handleChange}
                        placeholder="Enter your extension number"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">PAN Number</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <CreditCard className="h-5 w-5 text-blue-500" />
                      </div>
                      <input
                        type="text"
                        name="panNumber"
                        value={formData.panNumber}
                        onChange={handleChange}
                        placeholder="Enter your PAN number"
                        className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Service Type</label>
                    <div className="flex space-x-4">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="serviceType"
                          value="active"
                          checked={formData.serviceType === "active"}
                          onChange={handleChange}
                          className="form-radio text-blue-500 focus:ring-blue-500"
                        />
                        <span className="ml-2">Active</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="serviceType"
                          value="retired"
                          checked={formData.serviceType === "retired"}
                          onChange={handleChange}
                          className="form-radio text-blue-500 focus:ring-blue-500"
                        />
                        <span className="ml-2">Retired</span>
                      </label>
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Role</label>
                    <div className="flex flex-wrap gap-4">
                      {["regular", "contract", "retired", "student"].map((role) => (
                        <label key={role} className="inline-flex items-center">
                          <input
                            type="radio"
                            name="role"
                            value={role}
                            checked={formData.role === role}
                            onChange={handleChange}
                            className="form-radio text-blue-500 focus:ring-blue-500"
                          />
                          <span className="ml-2 capitalize">{role}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 rounded-xl font-semibold hover:from-blue-600 hover:to-indigo-700 transition-all transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed flex justify-center items-center group"
                  >
                    {isLoading ? (
                      <div className="w-6 h-6 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                    ) : (
                      <>
                        Update Profile
                        <Radio className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      <ToastContainer 
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}