import React, { useEffect, useState, useRef } from 'react';
import {
  List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Button, Box,
  IconButton, AppBar, Toolbar, useMediaQuery, Drawer, ListItemIcon,
  Container, Fade, Grow
} from '@mui/material';
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { list, getUrl, uploadData } from "aws-amplify/storage";
import { signOut } from "aws-amplify/auth";
import {
  Description as DescriptionIcon,
  CloudDownload as CloudDownloadIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Message as MessageIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
  CloudUpload as CloudUploadIcon
} from '@mui/icons-material';
import forms from '../Images/life.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const LifeCertificate = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { detailSend } = location.state || {};
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    setIsLoading(true);
    try {
      const result = await list({
        prefix: `live/name=${detailSend.name} pan=${detailSend.pan}`,
      });
      setFiles(result.items);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (key) => {
    try {
      const { url } = await getUrl({ key: key, options: { accessLevel: 'guest' } });
      window.open(url.href);
    } catch (error) {
      console.error('Error getting signed URL:', error);
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      await uploadData({
        key: `live/name=${detailSend.name} pan=${detailSend.pan}/${file.name}`,
        data: file,
        options: {
          accessLevel: 'guest',
        }
      }).result;
      fetchFiles(); // Refresh the file list after upload
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/admin_aries_app/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleHelp = () => {
    const email = 'syserp@aries.res.in';
    const subject = 'Need help With Aries Connect';
    window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}`);
  };

  const extractName = (key) => {
    const parts = key.split('/');
    return parts.slice(2).join('/');
  };

  const drawerContent = (
    <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" component="div">
          Aries Connect
        </Typography>
      </Box>
      <List sx={{ flexGrow: 1 }}>
        {[
          { text: 'Home', icon: <HomeIcon />, action: () => handleNavigation('/admin_aries_app/Home') },
          { text: 'User Info', icon: <PersonIcon />, action: () => handleNavigation('/admin_aries_app/UserInfo') },
          { text: 'Messages', icon: <MessageIcon />, action: () => handleNavigation('/admin_aries_app/Msg') },
          { text: 'Help', icon: <HelpIcon />, action: handleHelp },
        ].map((item, index) => (
          <Grow in={true} key={item.text} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + index * 200 }}>
            <StyledListItem button onClick={item.action}>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          </Grow>
        ))}
      </List>
      <Fade in={true} style={{ transitionDelay: '800ms' }}>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
            sx={{ backgroundColor: '#ff5309', '&:hover': { backgroundColor: '#e64a00' } }}
          >
            Logout
          </Button>
        </Box>
      </Fade>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <img
                src={forms}
                alt="Life Certificate Icon"
                style={{ width: '30px', marginRight: '12px' }}
              />
              <Typography variant="h6" component="div">
                Life Certificate
              </Typography>
            </Box>
            {!isMobile && (
              <>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Home')}>Home</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/UserInfo')}>User Info</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Msg')}>Messages</Button>
                <Button color="inherit" onClick={handleHelp}>Help</Button>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        <StyledDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {drawerContent}
        </StyledDrawer>

        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            mb: 3 
          }}>
            <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              onClick={() => fileInputRef.current.click()}
              sx={{ 
                
                minWidth: '200px',
                width: isMobile ? '100%' : 'auto'
              }}
            >
              Upload Certificate
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleUpload}
              style={{ display: 'none' }}
            />
          </Box>

          {isLoading ? (
            <Typography align="center">Loading certificates...</Typography>
          ) : (
            <List>
              {files.map((file, index) => (
                <Grow in={true} key={file.key} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 500 + index * 100 }}>
                  <ListItem
                    sx={{
                      mb: 2,
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      boxShadow: 1,
                      '&:hover': {
                        bgcolor: 'action.hover',
                        transform: 'translateY(-4px)',
                        transition: 'transform 0.3s ease-in-out',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <StyledAvatar>
                        <DescriptionIcon />
                      </StyledAvatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={extractName(file.key)}
                      secondary={new Date(file.lastModified).toLocaleDateString()}
                    />
                    <IconButton
                      onClick={() => handleDownload(file.key)}
                      color="primary"
                      sx={{
                        '&:hover': {
                          bgcolor: 'primary.light',
                          transform: 'scale(1.1)',
                          transition: 'all 0.3s ease-in-out',
                        },
                      }}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </ListItem>
                </Grow>
              ))}
            </List>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default LifeCertificate;