import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// AUTH PAGES
import SignUp from './Auth/SignUp';
import Additional_Information from './Auth/Additional_Information';
import Login from './Auth/Login';
import ConfirmOtp from './Auth/ConfirmOtp';
import ConfirmPassword from './Auth/ConfirmPassword';
import ForgetPassword from './Auth/ForgetPassword';
import Order from './Auth/Order';
import Home from './Auth/Home';
import NoticePage from './Auth/NoticePage';
import NpsPage from './Auth/NpsPage';
import PolicyPage from './Auth/PolicyPage';
import FormsPage from './Auth/FormsPage';
import PaySlip from './Auth/PaySlip';
import LifeCertificate from './Auth/LifeCertificate';
import OfficeOrders from './Auth/OfficeOrders';
import GalleryDetails from './Components/GalleryDeatils';
import UserInfo from './Components/UserInfo';
import MsgDetails from './Auth/MsgDetail';
// COMPONENTS PAGES
import Gallery from './Components/Gallery';
import MsgPage from './Auth/Msg';
import ResetPassword from'./Auth/forgot_pass_login time';
import FAQPage from './Auth/faq';
import ImageViewer from './Auth/ImageViewer'
import AuthGuard from './AuthGuard'; // Import AuthGuard component
import ImageViewerTel from"./Auth/ImageViewerTel"
import Contact from "./Auth/Contact"
function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Login />} />
         <Route path="/admin_aries_app/" element={<Login />} />
        <Route path="/admin_aries_app/Login" element={<Login />} />
        <Route path="/admin_aries_app/SignUp" element={<SignUp />} />
        <Route path="/admin_aries_app/Additional_Information" element={<Additional_Information />} />
        <Route path="/admin_aries_app/ConfirmOtp" element={<ConfirmOtp />} />
        <Route path="/admin_aries_app/ResetPassword" element={<ResetPassword />} />
        <Route path="/admin_aries_app/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/admin_aries_app/ConfirmPassword" element={<ConfirmPassword />} />
        
        <Route path="/admin_aries_app/Gallery" element={<AuthGuard><Gallery /></AuthGuard>} />
        <Route path="/admin_aries_app/Order" element={<AuthGuard><Order /></AuthGuard>} />
        <Route path="/admin_aries_app/Home" element={<AuthGuard><Home /></AuthGuard>} />
        <Route path="/admin_aries_app/NpsPage" element={<AuthGuard><NpsPage /></AuthGuard>} />
        <Route path="/admin_aries_app/NoticePage" element={<AuthGuard><NoticePage /></AuthGuard>} />
        <Route path="/admin_aries_app/PolicyPage" element={<AuthGuard><PolicyPage /></AuthGuard>} />
        <Route path="/admin_aries_app/FormsPage" element={<AuthGuard><FormsPage /></AuthGuard>} />
        <Route path="/admin_aries_app/PaySlip" element={<AuthGuard><PaySlip /></AuthGuard>} />
        <Route path="/admin_aries_app/LifeCertificate" element={<AuthGuard><LifeCertificate /></AuthGuard>} />
        <Route path="/admin_aries_app/OfficeOrders" element={<AuthGuard><OfficeOrders /></AuthGuard>} />
        <Route path="/admin_aries_app/GalleryDetails" element={<AuthGuard><GalleryDetails /></AuthGuard>} />
        <Route path="/admin_aries_app/UserInfo" element={<AuthGuard><UserInfo /></AuthGuard>} />
        <Route path="/admin_aries_app/Msg" element={<AuthGuard><MsgPage /></AuthGuard>} />
        <Route path="/admin_aries_app/Msgdetails" element={<AuthGuard><MsgDetails /></AuthGuard>} />
        <Route path="/admin_aries_app/faq" element={<FAQPage />} />
        <Route path='/admin_aries_app/Img' element={<AuthGuard><ImageViewer></ImageViewer></AuthGuard>}/>
        <Route path="/admin_aries_app/TelImg"element={<AuthGuard><ImageViewerTel></ImageViewerTel></AuthGuard>}></Route>
        <Route path="/admin_aries_app/Contact"element={<AuthGuard><Contact></Contact  ></AuthGuard>}></Route>

      </Routes>
    </Router>
  );
}

export default App;
