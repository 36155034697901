import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { DataStore } from 'aws-amplify/datastore';
import { USER } from '../models';
import { 
  TextField, Button, CircularProgress, Typography, Container, Grid, Box, 
  AppBar, Toolbar, IconButton, useMediaQuery, Drawer, List, ListItem, 
  ListItemIcon, ListItemText, Avatar, Dialog, DialogTitle, DialogContent, 
  DialogContentText, DialogActions, Grow, Fade
} from '@mui/material';
import { ThemeProvider, useTheme, styled } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Message as MessageIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import user from '../Images/User-Profile.png';


const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const UserInfo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { userId } = await getCurrentUser();
      const response = await DataStore.query(USER);
      const currentUser = response.find(user => user.uid === userId);
      if (currentUser) {
        setUserDetails(currentUser);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.log(e);
    }
  };

  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.open(mailtoLink);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setOpenConfirmation(true);
  };

  const handleSaveConfirmed = async () => {
    try {
      const { userId } = await getCurrentUser();
      const model = await DataStore.query(USER, user => user.uid.eq(userId));
      if (model && model.length > 0) {
        await DataStore.save(USER.copyOf(model[0], item => {
          item.email = userDetails.email;
          item.phone = userDetails.phone;
          item.name = userDetails.name;
          item.designation = userDetails.designation;
          item.division = userDetails.division;
        }));
      }
      setOpenConfirmation(false);
      setSuccessMessage("User details saved successfully.");
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleChangePassword = () => {
    navigate("/admin_aries_app/ForgetPassword");
  };

  const drawerContent = (
    <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" component="div">
          Aries Connect
        </Typography>
      </Box>
      <List sx={{ flexGrow: 1 }}>
        {[
          { text: 'Home', icon: <HomeIcon />, action: () => handleNavigation('/admin_aries_app/Home') },
          { text: 'User Info', icon: <PersonIcon />, action: () => handleNavigation('/admin_aries_app/UserInfo') },
          { text: 'Messages', icon: <MessageIcon />, action: () => handleNavigation('/admin_aries_app/Msg') },
          { text: 'Help', icon: <HelpIcon />, action: handleHelp },
        ].map((item, index) => (
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + index * 200 }} key={item.text}>
            <StyledListItem button onClick={item.action}>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          </Grow>
        ))}
      </List>
      <Fade in={true} style={{ transitionDelay: '800ms' }}>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
            sx={{ backgroundColor: '#ff5309', '&:hover': { backgroundColor: '#e64a00' } }}
          >
            Logout
          </Button>
        </Box>
      </Fade>
    </Box>
  );

  if (loading) {
    return (
      <Container maxWidth="xs">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              User Profile
            </Typography>
            {!isMobile && (
              <>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Home')}>Home</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/UserInfo')}>User Info</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Msg')}>Messages</Button>
                <Button color="inherit" onClick={handleHelp}>Help</Button>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
              </>
            )}
          </Toolbar>
        </AppBar>
        <StyledDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {drawerContent}
        </StyledDrawer>

        <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
            p: 3,
          }}>
            <Avatar
              src={user}
              alt="User Profile"
              sx={{
                width: 120,
                height: 120,
                mb: 2,
                border: '2px solid #fff',
                boxShadow: 2,
              }}
            />
            <Typography variant="h5" sx={{ mb: 1 }}>
              {userDetails.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3 }}>
              Employee ID: {userDetails.EmpID}
            </Typography>

            <form onSubmit={handleSave} style={{ width: '100%' }}>
              <Grid container spacing={2}>
                {['email', 'phone', 'name', 'division'].map(key => (
                  <Grid item xs={12} sm={6} key={key}>
                    <TextField
                      label={key.charAt(0).toUpperCase() + key.slice(1)}
                      name={key}
                      value={userDetails[key] || ''}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      required={key !== 'email'}
                      disabled={key === 'email'}
                    />
                  </Grid>
                ))}
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<SaveIcon />}
                sx={{ mt: 3, mb: 2 }}
              >
                Save Changes
              </Button>
            </form>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleChangePassword}
              sx={{ mt: 1 }}
            >
              Change Password
            </Button>
          </Box>
        </Container>

        <Dialog
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
        >
          <DialogTitle>Confirm Save</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to save these details?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmation(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveConfirmed} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {successMessage && (
          <Box sx={{
            position: 'fixed',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            bgcolor: 'success.main',
            color: 'white',
            p: 2,
            borderRadius: 1,
          }}>
            <Typography>{successMessage}</Typography>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default UserInfo;