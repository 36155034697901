import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff, Mail, Lock, ArrowRight, ArrowLeft } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (step === 1) {
      await handleResetPassword(formData.email);
    } else if (step === 2) {
      if (formData.newPassword !== formData.confirmPassword) {
        toast.error("New password and confirm password do not match.", {
          style: { fontWeight: "bold", fontSize: "16px" }
        });
        setIsLoading(false);
        return;
      }
      await handleConfirmResetPassword({
        username: formData.email,
        confirmationCode: formData.otp,
        newPassword: formData.newPassword,
      });
    }

    setIsLoading(false);
  };

  const handleResetPassword = async (username) => {
    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output);
    } catch (error) {
      console.log(error);
      toast.error("Failed to send reset code. Please try again.", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
    }
  };

  const handleResetPasswordNextSteps = (output) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        toast.info(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`, {
          style: { fontWeight: "bold", fontSize: "16px" }
        });
        setStep(2);
        break;
      case 'DONE':
        toast.success("Successfully reset password.", {
          style: { fontWeight: "bold", fontSize: "16px" }
        });
        navigate("/admin_aries_app/login");
        break;
      default:
        toast.error("An unexpected error occurred. Please try again.", {
          style: { fontWeight: "bold", fontSize: "16px" }
        });
        console.error("Unexpected reset password step:", nextStep.resetPasswordStep);
    }
  };

  const handleConfirmResetPassword = async ({ username, confirmationCode, newPassword }) => {
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
      toast.success("Password updated successfully", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
      navigate("/admin_aries_app/login");
    } catch (error) {
      console.log(error);
      toast.error("Failed to reset password. Please try again.", {
        style: { fontWeight: "bold", fontSize: "16px" }
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-indigo-50 to-purple-100 flex flex-col">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-bl from-blue-300 to-indigo-400 rounded-bl-full opacity-20 transform rotate-12"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-to-tr from-indigo-300 to-purple-400 rounded-tr-full opacity-20 transform -rotate-12"></div>

      <div className="flex justify-between p-6 relative z-10">
        <div className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
          AriesConnect
        </div>
        <a href="/admin_aries_app/faq" className="text-gray-600 hover:text-blue-600 transition-colors">
          Need Help?
        </a>
      </div>

      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-md relative z-10">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <div className="bg-white backdrop-blur-lg bg-opacity-70 rounded-3xl shadow-2xl overflow-hidden border border-gray-100">
              <div className="px-8 pt-8 pb-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">
                  {step === 1 ? "Forgot Password" : "Reset Password"}
                </h1>
                <p className="text-gray-500 mb-8">
                  {step === 1 ? "Enter your email to receive a reset code" : "Enter the code and your new password"}
                </p>

                <form onSubmit={handleSubmit} className="space-y-6">
                  {step === 1 ? (
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">Email</label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Mail className="h-5 w-5 text-blue-500" />
                        </div>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                          className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                          required
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Reset Code</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Lock className="h-5 w-5 text-blue-500" />
                          </div>
                          <input
                            type="text"
                            name="otp"
                            value={formData.otp}
                            onChange={handleChange}
                            placeholder="Enter the reset code"
                            className="block w-full pl-10 pr-3 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                            required
                          />
                        </div>
                      </div>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">New Password</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Lock className="h-5 w-5 text-blue-500" />
                          </div>
                          <input
                            type={showNewPassword ? "text" : "password"}
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            placeholder="Enter your new password"
                            className="block w-full pl-10 pr-10 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                            required
                          />
                          <button
                            type="button"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-blue-500"
                          >
                            {showNewPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                          </button>
                        </div>
                      </div>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Lock className="h-5 w-5 text-blue-500" />
                          </div>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm your new password"
                            className="block w-full pl-10 pr-10 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                            required
                          />
                          <button
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-blue-500"
                          >
                            {showConfirmPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 rounded-xl font-semibold hover:from-blue-600 hover:to-indigo-700 transition-all transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed flex justify-center items-center group"
                  >
                    {isLoading ? (
                      <div className="w-6 h-6 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                    ) : (
                      <>
                        {step === 1 ? "Send Reset Code" : "Reset Password"}
                        <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                      </>
                    )}
                  </button>
                </form>

                <div className="mt-8 space-y-2 text-center">
                  <button
                    onClick={() => navigate("/admin_aries_app/login")}
                    className="text-blue-600 hover:text-blue-500 transition-colors font-medium flex items-center justify-center"
                  >
                    <ArrowLeft className="mr-2 h-5 w-5" />
                    Back to Login
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}