import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';

const AuthGuard = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {

      
      const token = localStorage.getItem('authToken');
      const expirationTime = localStorage.getItem('authTokenExpiration');

      if (!token || !expirationTime) {
        await signOut();
        console.log("token not found")
        setIsAuthenticated(false);
        return;
      }

      const currentTime = new Date().getTime();

      if (currentTime >= expirationTime) {
        console.log("token expired")
        localStorage.removeItem('authToken');
        localStorage.removeItem('authTokenExpiration');
        await signOut();
        setIsAuthenticated(false);
        return;
      }

      setIsAuthenticated(true); 
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/admin_aries_app/login" />;
};

export default AuthGuard;
