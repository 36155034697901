import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CssBaseline, Typography, Box, IconButton, CircularProgress, useMediaQuery, Skeleton } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ArrowBack, FileDownload } from '@mui/icons-material';
import { getUrl, list } from 'aws-amplify/storage';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';


const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
      marginBottom: '1rem',
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          },
        },
      },
    },
  },
});
const useImageLoader = (src) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return loaded;
};

const GalleryDetails = () => {
  const { state } = useLocation();
  const { key, eventName } = state;
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const imagesPerPage = 300;
  useEffect(() => {
    fetchImageUrls();
  }, []);

  const fetchImageUrls = async () => {
    try {
      const result = await list({ prefix: key });
      const urls = await Promise.all(
        result.items.slice(1).map(async (item) => {
          const imageUrl = await getUrl({
            key: item.key,
            options: { accessLevel: 'guest' },
          });
          return {
            original: imageUrl.url.href,
            thumbnail: imageUrl.url.href,
            description: item.key.split('/').pop(),
          };
        })
      );
      setImageUrls(urls);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching images:', error);
      setLoading(false);
    }
  };

  const handleBack = () => navigate("/admin_aries_app/gallery");

  const ImageItem = ({ item }) => {
    const imageLoaded = useImageLoader(item.original);

    return (
      <div className="image-gallery-image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {!imageLoaded && (
          <Skeleton 
            variant="rectangular" 
            width="100%" 
            height={isMobile ? 'calc(100vh - 120px)' : 'calc(100vh - 200px)'}
            animation="wave"
          />
        )}
        <Typography
          variant="subtitle2"
          sx={{
            position: 'absolute',
            top: 10,
            left: 10,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '3px 6px',
            borderRadius: '4px',
            fontSize: isMobile ? '0.7rem' : '0.8rem',
            zIndex: 1,
          }}
        >
          {item.description}
        </Typography>
        <img
          src={item.original}
          alt={item.description}
          style={{
            maxHeight: isMobile ? 'calc(100vh - 120px)' : 'calc(100vh - 200px)',
            maxWidth: '100%',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain',
            display: imageLoaded ? 'block' : 'none',
          }}
        />
        <IconButton
          onClick={() => window.open(item.original)}
          sx={{
            position: 'absolute',
            bottom: 10,
            left: 10,
            color: 'primary.main',
            padding: isMobile ? '6px' : '8px',
            zIndex: 1,
          }}
          aria-label="download"
        >
          <FileDownload fontSize={isMobile ? 'small' : 'medium'} />
        </IconButton>
      </div>
    );
  };

  const renderCustomItem = useCallback((item) => <ImageItem item={item} />, []);

  const renderCustomThumbnail = useCallback((item) => (
    <div className="image-gallery-thumbnail">
      <img src={item.thumbnail} alt={item.description} style={{ width: '100%', height: 'auto' }} loading="lazy" />
    </div>
  ), []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ py: 2, px: isMobile ? 1 : 2 }}>
        <IconButton 
          onClick={handleBack} 
          sx={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h3" align="center" gutterBottom sx={{ mt: 5, fontSize: isMobile ? '1.5rem' : '2rem' }}>
          {eventName}
        </Typography>
        <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden', boxShadow: 3 }}>
          <ImageGallery 
            items={imageUrls} 
            showPlayButton={false}
            showFullscreenButton={true}
            renderItem={renderCustomItem}
            renderThumbInner={renderCustomThumbnail}
            additionalClass="app-image-gallery"
            useBrowserFullscreen={false}
            lazyLoad={true}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GalleryDetails;