import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

const FAQPage = () => {
  const [expanded, setExpanded] = useState([]);

  const toggleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-indigo-50 to-purple-100 flex flex-col">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-bl from-blue-300 to-indigo-400 rounded-bl-full opacity-20 transform rotate-12"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-to-tr from-indigo-300 to-purple-400 rounded-tr-full opacity-20 transform -rotate-12"></div>

      <div className="flex justify-between p-6 relative z-10">
        <div className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
          AriesConnect
        </div>
        <a href="/admin_aries_app/" className="text-gray-600 hover:text-blue-600 transition-colors">
          Back to Home
        </a>
      </div>

      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-3xl relative z-10">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <div className="bg-white backdrop-blur-lg bg-opacity-70 rounded-3xl shadow-2xl overflow-hidden border border-gray-100 p-8">
              <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">Frequently Asked Questions</h1>
              <div className="space-y-6">
                {questions.map((qa, index) => (
                  <motion.div
                    key={index}
                    initial={false}
                    animate={{ backgroundColor: expanded[index] ? "rgba(239, 246, 255, 0.6)" : "rgba(255, 255, 255, 0.6)" }}
                    className="rounded-xl overflow-hidden shadow-md"
                  >
                    <motion.header
                      initial={false}
                      onClick={() => toggleExpand(index)}
                      className="bg-white bg-opacity-60 p-4 flex justify-between items-center cursor-pointer"
                    >
                      <h2 className="text-lg font-semibold text-gray-700">{qa.question}</h2>
                      <motion.div
                        animate={{ rotate: expanded[index] ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <ChevronDown className="w-5 h-5 text-blue-500" />
                      </motion.div>
                    </motion.header>
                    <AnimatePresence initial={false}>
                      {expanded[index] && (
                        <motion.div
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                          }}
                          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                        >
                          <div className="p-4 text-gray-600">
                            {qa.answer}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

const questions = [
  {
    question: 'What is AriesConnect?',
    answer: 'AriesConnect is an application designed for employees of the Aryabhata Research Institute of Observational Sciences. It serves as a comprehensive platform allowing employees to access their salary slips, office correspondence, government orders, NPS updates, telephone directory, holidays, and ERP functionalities, all within a single app.',
  },
  {
    question: 'How do I sign up for AriesConnect?',
    answer: 'To sign up for AriesConnect, simply download the mobile app from aries.res.in. Once installed, open the app and follow the on-screen instructions to create your account. You will be prompted to provide some basic information and choose a username and password.',
  },
  {
    question: 'I forgot my password. What should I do?',
    answer: 'If you forgot your password, don\'t worry! On the login screen, there should be an option to reset your password. Click on the "Forgot Password" link, and follow the instructions to reset your password. You may be asked to provide your email address or phone number associated with your account for verification purposes.',
  },
  {
    question: 'Is my personal information secure on AriesConnect?',
    answer: 'Yes, we take the privacy and security of our users\' information very seriously. We use industry-standard encryption protocols to protect your data and have strict privacy policies in place to ensure that your personal information is not shared with third parties without your consent.',
  },
  {
    question: 'How can I contact support if I have further questions or issues?',
    answer: 'If you have any further questions, encounter technical issues, or need assistance with anything related to AriesConnect, please don\'t hesitate to contact our support team. You can usually find a "Help Us Section" option within the app, where you can submit your queries or issues. Our support team will respond to you as soon as possible and assist you with your concerns.',
  },
];

export default FAQPage;